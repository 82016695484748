import * as React from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import {Helmet} from "react-helmet";

import siteSettings from "../constants/site-settings";

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import ContactFormComponent from "../components/contact-form";

import '../styles/services.scss'

const ServicesPage = ({data}) => {

  const fluidTopImgSource = [
    data.top.childImageSharp.fluid,
  ];

  const fluidServices1ImgSource = [
    data.lgServices1.childImageSharp.fluid,
  ];

  const fluidServices2ImgSource = [
    data.lgServices2.childImageSharp.fluid,
  ];

  const fluidServices3ImgSource = [
    data.lgServices3.childImageSharp.fluid,
  ];

  const fluidServices4ImgSource = [
    data.lgServices4.childImageSharp.fluid,
  ];

  const paymentImgSource = [
    data.payment.childImageSharp.fluid,
  ];

  const warranty1ImgSource = [
    data.warranty1.childImageSharp.fluid,
  ];

  const warranty2ImgSource = [
    data.warranty2.childImageSharp.fluid,
  ];

  const warranty3ImgSource = [
    data.warranty3.childImageSharp.fluid,
  ];

  const metaTitle = `${siteSettings.siteTitle} - GDB Garage Door - Commercial and Residential Service in Chicagoland`;
  const metaDescription = ` Local Trusted Garage Door Service| Commercial and Residential Installation, repair, Maintenance | Free quote | ${siteSettings.phoneNumberPretty}`;

  return (
    <div className="w-full">
      <Helmet>
        <title>{metaTitle}</title>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover"/>
        <meta name="description" content={metaDescription}/>
        <meta name="keywords" content="garage door repair, garage door springs, garage door installation, overhead garage door, local garage door, garage door"/>

        <meta http-equiv="refresh" content="0;url=https://www.gdbgaragedoorservice.com/"/>

        <meta property="og:site_name" content={siteSettings.siteTitle}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={metaTitle}/>
        <meta property="og:description" content={siteSettings.siteDescription}/>
        <meta property="og:url" content={siteSettings.siteUrl}/>
        <meta property="og:type" content="Landing page"/>

        <meta property="og:image" content={siteSettings.image}/>
        <meta property="og:image:width" content={siteSettings.shareImageWidth}/>
        <meta property="og:image:height" content={siteSettings.shareImageHeight}/>

        <meta name="twitter:title" content={metaTitle}/>
        <meta name="twitter:description" content={siteSettings.siteDescription}/>
        <meta name="twitter:url" content={siteSettings.siteUrl}/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content={siteSettings.image}/>

        <meta name="google-site-verification" content="_15yVB2JdnGYJioiAfQOr1Ic_rwEdE34nZIg8EKsCTk" />

        <link rel="canonical" href={siteSettings.siteUrl}/>

        <script defer data-domain="gdb-repair.com" src="https://plausible.io/js/plausible.js"/>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "GDB Garage Door Repair",
            "image": "https://www.gdb-repair.com/main.webp",
            "@id": "",
            "url": "https://www.gdb-repair.com/",
            "telephone": "773-956-1198",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1043 S York Rd Ste",
              "addressLocality": "Bensenville",
              "addressRegion": "IL",
              "postalCode": "60106",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 41.933984,
              "longitude": -87.93830559999999
            },
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "07:00",
              "closes": "22:00"
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Saturday",
                "Sunday"
              ],
              "opens": "07:00",
              "closes": "20:00"
            }],
            "sameAs": [
              "https://www.facebook.com/GDB.GarageDoorRepair",
              "https://www.instagram.com/gdbgaragedoor/",
              "https://twitter.com/DoorGdb",
              "https://www.youtube.com/channel/UCK8N5Pkvm2G_RarsfRfPjYQ",
              "https://www.gdb-repair.com/"
            ] 
          })}
        </script>
      </Helmet>

      <HeaderComponent/>
      <main className="services">
        <div className="top-row relative">
          <Img fluid={fluidTopImgSource} objectFit="cover" className="image" alt="Website Design"/>
            <div className="description mx-auto px-5 flex flex-col gap-3 justify-center items-center">
              <h1 className="text-center">LICENSED. BONDED & INSURED.</h1>
              <p className="text-center"></p>
              <p className="text-center">Our team is available seven days a week, 24 hours a day, to provide emergency garage door service.</p>
              <a className="gdb-btn primary wide flex" href={siteSettings.phoneNumberLink}>Call garage door specialist</a>
            </div>
        </div>
        <div className="services-row">
          <div className="container mx-auto px-5 py-10">
            <h2 className="mb-10 text-center">Our services</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10 justify-center">
              <div className="service flex flex-col">
                <Img fluid={fluidServices1ImgSource} objectFit="contain" className="image" alt="GDB Garage Door Repair"/>
                <div className="p-5">
                  <h4 className="mb-3">GARAGE DOOR SPRINGS REPLACEMENT</h4>
                  <p>Garage door springs are an essential component of a garage door system, as they provide the necessary force to lift and lower the door. They work by counterbalancing the weight of the door, making it easier to open and close manually or with the assistance of an automatic garage door opener.</p>
                  <p>There are two main types of garage door springs: torsion springs and extension springs.
                    Both torsion springs and extension springs are made of high-tensile steel and are designed to withstand the heavy weight of the garage door. However, torsion springs are considered to be more durable and offer better balance and stability compared to
                    extension springs.</p>
                  <p>It's important to note that garage door springs are under high tension and can be dangerous if mishandled. If a spring breaks or malfunctions, it can cause the door to become heavy or unbalanced, posing a risk of injury. Therefore, it is recommended to consult a professional garage door technician for installation, maintenance, or repair of garage door springs.</p>
                </div>
              </div>
              <div className="service flex flex-col">
                <Img fluid={fluidServices2ImgSource} objectFit="contain" className="image" alt="Overhead Garage Door Installation"/>
                <div className="p-5">
                  <h4 className="mb-3">Garage Door Repairs & Maintenance</h4>
                  <p>Garage door repairs and maintenance are crucial to ensure the smooth operation, safety, and longevity of your garage door system. Regular maintenance and prompt repairs can help prevent major issues, improve performance, and extend the lifespan of your garage door. </p>
                  <p>Here is a description of garage door repairs and maintenance:
                    Lubrication
                    Visual Inspection
                    Test Safety Features
                    Garage Door Opener Maintenance
                    Spring Replacement
                    Track Alignment and Cleaning
                    Weatherstripping Replacement
                    Professional Maintenance</p>
                  <p>Remember, garage doors are heavy and complex systems, and attempting complicated repairs or adjustments without proper knowledge and tools can be dangerous. It is recommended to seek professional assistance for any significant repairs or if you are unsure about performing maintenance tasks yourself.</p>
                </div>
              </div>
              <div className="service flex flex-col">
                <Img fluid={fluidServices3ImgSource} objectFit="contain" className="image" alt="Overhead Garage Door Product"/>
                <div className="p-5">
                  <h4 className="mb-3">Garage Door Opener Repair & Installation</h4>
                  <p>Garage door opener repair and installation services are essential for ensuring the smooth and reliable operation of your garage door. Here is a description of garage door opener repair and installation:</p>
                  <p>Repair Services:
                    Troubleshooting
                    Motor Repair or Replacement
                    Gear and Sprocket Replacement
                    Sensor Alignment and Repair</p>
                  <p>Installation Services:
                    Opener Selection
                    Wiring and Electrical Setup
                    Mounting and Balancing
                    Safety Features Setup</p>
                  <p>Professional garage door opener repair and installation services are recommended because they involve working with electrical components, heavy machinery, and potentially hazardous situations. A skilled technician will have the expertise, tools, and knowledge to handle these tasks safely and effectively, ensuring that your garage door opener operates reliably and securely.</p>
                </div>
              </div>
              <div className="service flex flex-col">
                <Img fluid={fluidServices4ImgSource} objectFit="contain" className="image" alt="Garage Door Service installation"/>
                <div className="p-5">
                  <h4 className="mb-3">Garage Door Sales & Installation</h4>
                  <p>Garage door installation involves the process of setting up a new garage door system for residential or commercial properties.
                    Here are five sentences describing garage door installation:
                    Garage door installation begins with careful measurements of the garage opening to ensure a proper fit for the new door.
                    Skilled technicians remove the old door (if applicable) and prepare the opening for the installation of the new door.
                    The installation process includes assembling the door sections, attaching tracks and hardware, and ensuring proper alignment and balance.
                    Garage door installation also involves installing the necessary components such as springs, cables, rollers, and hinges to ensure smooth and efficient operation.
                    Once the door is installed, technicians test the functionality of the garage door opener, remote controls, safety features, and perform any necessary adjustments to ensure optimal performance and customer satisfaction.</p>
                  <p>Professional garage door installation is recommended to ensure that the door is installed correctly, guaranteeing safe and reliable operation. It is important to consult with experienced technicians who have the expertise and knowledge to handle the installation process efficiently.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contacts-row">
          <div className="container mx-auto px-5 py-10">
            <div className="flex flex-row justify-center">
              <div className="flex flex-row gap-10 justify-center items-center w-full">
                <h2>PROFESSIONAL GARAGE DOOR SERVICE</h2>
                <a className="gdb-btn primary flex" href={siteSettings.phoneNumberLink}>Call the PROFESSIONAL</a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterComponent/>
    </div>
  )
}

export default ServicesPage

export const query = graphql`
    query {
        top: file(relativePath: { eq: "services-top.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lgServices1: file(relativePath: { eq: "service1.webp" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lgServices2: file(relativePath: { eq: "service2.webp" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lgServices3: file(relativePath: { eq: "service3.webp" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        lgServices4: file(relativePath: { eq: "service4.webp" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        payment: file(relativePath: { eq: "payment.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        warranty1: file(relativePath: { eq: "warranty1.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        warranty2: file(relativePath: { eq: "warranty2.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        warranty3: file(relativePath: { eq: "warranty3.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
